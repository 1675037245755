import React from 'react';
import '../TimeTest/style.scss';
import TimeTested from '@/components/common/TimeTested';
const TimeTest: React.FC = () => {
  return (
    <>
      <section className="main-timetested-content">
        <TimeTested />
      </section>
    </>
  );
};

export default React.memo(TimeTest);
