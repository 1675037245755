import React from 'react';
import '../Testimonials/style.scss';
import Image from 'next/image';
import TextShorten from '@/components/common/UI/TextShorten';
const Testimonials: React.FC = () => {
  return (
    <>
      <section className="section-spacing testimonial-align-view test-users">
        <div className="section-container">
          <h2 className="main-section-head text-white text-center">
            Hear from our learners
          </h2>
          <span className="shape-spot">
            <Image
              src="/Ellipse 93.svg"
              alt="shape"
              title="shape"
              width={154}
              height={154}
              loading="lazy"
            />
          </span>
          <p className="mb-28">
            <Image
              src="/Frame (3).svg"
              alt="divider"
              title="divider"
              width={495}
              height={23}
              loading="lazy"
            />
          </p>
          <div className="users-quotes-content">
            <div className="row">
              <div className="user-card-wrap">
                <div className="user-card-content">
                  <h2 className="pb-1">Performance Oriented</h2>
                  <p className="pb-2">
                    <Image
                      src="/rate.svg"
                      alt="divider"
                      title="divider"
                      width={83}
                      height={13}
                      loading="lazy"
                    />
                  </p>
                  <p>
                    Thanks mam for teaching me a wonderful dance, I won{' '}
                    <b>Queen of the Function Award</b> by performing this dance.
                  </p>
                </div>
                <div className="user-circle">
                  <Image
                    src="/Mask group.svg"
                    alt="Kathak Aalamban Student"
                    title="user"
                    width={86}
                    height={88}
                    loading="lazy"
                  />
                </div>
                <div className="user-dist text-center">
                  <span>Aarti Vadhera</span>
                  <br />
                  <span>Kathak Aalamban</span>
                </div>
              </div>
              <div className="user-card-wrap active">
                <div className="user-card-content">
                  <h2 className="pb-1">Simplified Masterclass</h2>
                  <p className="pb-2">
                    <Image
                      src="/rate.svg"
                      alt="divider"
                      title="divider"
                      width={83}
                      height={13}
                      loading="lazy"
                    />
                  </p>
                  <p>
                    <TextShorten truncate={100}>
                      Masterclass video representation of the swar and how the
                      aaroh & avroh works, breaking down song into swars is what
                      I like the most. Personal change that I observe is i have
                      started listening to song more carefully.
                    </TextShorten>
                  </p>
                </div>
                <div className="user-circle">
                  <Image
                    src="/Mask group (2).svg"
                    alt="Kathak Aalamban Student - Artfills"
                    title="user"
                    width={86}
                    height={88}
                    loading="lazy"
                  />
                </div>
                <div className="user-dist text-center">
                  <span>Sailesh Ramaswamy</span>
                  <br />
                  <span>Vocals Aalamban</span>
                </div>
              </div>
              <div className="user-card-wrap">
                <div className="user-card-content">
                  <h2 className="pb-1">Fun Learning</h2>
                  <p className="pb-2">
                    <Image
                      src="/rate.svg"
                      alt="divider"
                      title="divider"
                      width={83}
                      height={13}
                      loading="lazy"
                    />
                  </p>
                  <p>
                    <TextShorten truncate={100}>
                      Thanks a lot for your wishes to Saanvi on course
                      completion and sharing the certification, also for the
                      encouraging words on her dance performance. She too
                      thoroughly enjoyed her dance lessons under the guidance of
                      Ms Bhakti. We plan to let Saanvi continue her dance class
                      so that she can learn, improve and work on dance form
                    </TextShorten>
                  </p>
                </div>
                <div className="user-circle">
                  <Image
                    src="/Mask group (3).svg"
                    alt="Training for Kathak Aalamban"
                    title="user"
                    width={86}
                    height={88}
                    loading="lazy"
                  />
                </div>
                <div className="user-dist text-center">
                  <span>Gaurav Jain - father of Sanvi Jain</span>
                  <br />
                  <span>Kathak Aadita</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Testimonials);
