import React from 'react';
import Image from 'next/image';
import './styles.scss';
import Button from '@/components/common/UI/Button';
import CourseSelectionDialog from '@/components/common/CourseSelectionDialog';
import { useAppContext } from '@/contexts/AppContextProvider';
import { isLoggedIn } from '@/utils';

const Benner: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { userDetail } = useAppContext();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="homeBanner main-home">
      <div className="banner-top-content">
        <div className="section-containers">
          <div className="row">
            <div className="self-center mobile-view-center-text max-w-mobile-view pt-0 pb-10  sm:py-14 banner-caption ">
              <div className="text-left">
                <h1 className="text-white">
                  Enable Creativity <br /> and Health for{' '}
                  <span className="yellowtext">a Joyful Life</span>
                </h1>
              </div>

              {!userDetail?.id && !isLoggedIn() && (
                <div className="ms-0 signup-now-btn lg:ms-20 lg:text-left xl:mt-2">
                  <Button
                    onClick={handleOpen}
                    classeName="lg:ms-12 text-lg btn-orange"
                    title="Sign Up Now"
                    showIcon={true}
                  />
                </div>
              )}
            </div>
            <div className="top-image-right-wrap">
              <Image
                // src="/main-home.png"
                src="/solo_web.png"
                alt="Online Kathak Classes for Adults"
                title="kathak-adults"
                width={1200}
                height={641}
                priority
                rel="preload"
                className="d-none-md-down"
              />
              <Image
                src="/solo_mobile.png"
                alt="kathak-adults"
                title="kathak-adults"
                width={780}
                height={528}
                priority
                rel="preload"
                className="d-none-md-up"
              />
            </div>
          </div>
        </div>
        <div className="online-courses">
          <div className="section-container">
            <div className="courses-wrap">
              <div className="left-courses-content">
                <ul>
                  <li>Learn Indian Arts</li>
                  <li>Live Online Classes</li>
                </ul>
              </div>
              {/* <div className="btn-hm home-banner-btn">
                <FreeTrialButton title="Book a Free Trial" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <CourseSelectionDialog open={open} handleClose={handleClose} />
    </div>
  );
};

export default React.memo(Benner);
