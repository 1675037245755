import React from 'react';
import Image from 'next/image';
import TextShorten from '../..//common/UI/TextShorten';
import '../AboutMaster/style.scss';

const AboutMaster: React.FC = () => {
  return (
    <>
      <div className="aboutmaster-content section-spacing">
        <div className="circle-shadow d-none-md-up"></div>
        <div className="section-container">
          <h2 className="main-section-head text-white">About the Masters </h2>
          <div className="aboutmaster-inner-content mb-55">
            <div className="row">
              <div className="circle-shadow d-none-md-down"></div>
              <div className="left-about">
                <span className="top-shape"></span>
                <p>
                  <Image
                    src="/d6 (1) 1.png"
                    alt="Bhakti Deshpande – Professional Kathak Dancer"
                    title="teg-line"
                    width={547}
                    height={441}
                    loading="lazy"
                    className="master1"
                  />
                  <Image
                    src="/master-bg.svg"
                    alt="teg-line"
                    title="teg-line"
                    width={547}
                    height={441}
                    loading="lazy"
                    className="d-none-md-up master-bg"
                  />
                </p>
                <div className="user-name-heading d-none-md-up">
                  <h3 className="text-center">Bhakti Deshpande</h3>
                </div>
              </div>
              <div className="right-about">
                <span className="right-desc-shape">
                  <Image
                    src="/Ellipse 89.svg"
                    alt="shape"
                    title="shape"
                    width={303}
                    height={303}
                    loading="lazy"
                  />
                </span>
                <p className="pts-10 d-none-md-down">
                  <Image
                    src="/exp.svg"
                    alt="teg-line"
                    title="teg-line"
                    width={84}
                    height={69}
                    loading="lazy"
                  />
                </p>
                <div className="desc pt-7 pb-5 text-right">
                  <TextShorten truncate={350}>
                    “A professional Kathak dancer having performed in
                    prestigious venues globally, an empanelled artist with the
                    indian ministry of culture, a Kathak Choreographer in
                    Bollywood movies like Baajirao Mastani, Vishwaroopam, Dedh
                    Isqiya and multiple dancer reality shows. She is the student
                    of Pandit Birju Maharaj ji and Pandit Suresh Talwalkar. But
                    most importantly she has been a passionate teacher since
                    more than a decade. She has designed and delivered the
                    Dance(Kathak) course.“
                  </TextShorten>
                </div>
                <div className="user-name-heading d-none-md-down">
                  <span>
                    <Image
                      src="/Group (2).svg"
                      alt="teg-line"
                      title="teg-line"
                      width={18}
                      height={18}
                      loading="lazy"
                    />
                  </span>
                  <h2>Bhakti Deshpande</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none-md-up divider-line">
            <Image
              src="/dv.svg"
              alt="divider"
              title="divider"
              width={255}
              height={12}
              loading="lazy"
              className="mx-auto"
            />
          </div>
          <div className="aboutmaster-inner-content aboutmaster-inner-content-singing about-mx">
            <div className="row">
              <div className="left-about">
                <span className="right-ab-shape1">
                  <Image
                    src="/Ellipse 89 (1).svg"
                    alt="teg-line"
                    title="teg-line"
                    className="d-none-md-down"
                    width={452}
                    height={452}
                    loading="lazy"
                  />
                  <Image
                    src="/shadow-ellips.svg"
                    alt="teg-line"
                    title="teg-line"
                    className="d-none-md-up"
                    width={452}
                    height={452}
                    loading="lazy"
                  />
                </span>
                <span className="right-ab-shape">
                  <Image
                    src="/Group 14869 (2).svg"
                    alt="teg-line"
                    title="teg-line"
                    width={510}
                    height={510}
                    loading="lazy"
                  />
                </span>
                <p>
                  <Image
                    src="/user-b1.svg"
                    alt="Learn Music from Saylee Talwalkar"
                    title="teg-line"
                    width={547}
                    height={441}
                    loading="lazy"
                    className="master2"
                  />
                  <Image
                    src="/master-bg.svg"
                    alt="teg-line"
                    title="teg-line"
                    width={547}
                    height={441}
                    loading="lazy"
                    className="d-none-md-up master-bg"
                  />
                </p>
                <div className="user-name-heading d-none-md-up">
                  <h3 className="text-center">Saylee Talwalkar</h3>
                </div>
              </div>
              <div className="right-about">
                <p className="pt-10 d-none-md-down">
                  <Image
                    src="/exp.svg"
                    alt="teg-line"
                    title="teg-line"
                    width={84}
                    height={69}
                    loading="lazy"
                  />
                </p>
                <div className="desc pt-12 pb-5">
                  <TextShorten truncate={450}>
                    “With a divinely mesmerising voice along with the unceasing
                    enthusiasm, Saylee Talwalkar has influentially made her
                    resonance felt in a profoundly infinite world of music.
                    Saylee is the disciple of Guru Mrs. Usha Deshpande ji, of
                    the Kirana Gharana; Mrs. Arati Ankalikar Tikekar ji of
                    Jaipur and Agra Gharana & Pandita Padma Talwalkar ji of
                    Jaipur-Atrauli Garana. Having imbibed the moral norms,
                    values and an in-depth understanding of not just one but
                    many gharanas that allows her to harmonize her distinctly
                    diverse styles into one enthralling rendition from classical
                    to semi-classical styles. She shares the credits of the
                    widely recognised album by the Jazz Saxophonist Mr. Finn
                    Martin from Germany. She has also performed with the world
                    renowned Jazz Pianist Mr. Louis Banks in his very own band,
                    Aakriti.“
                  </TextShorten>
                </div>
                <div className="user-name-heading d-none-md-down">
                  <h2>SAYLEE TALWALKAR</h2>
                  <span>
                    <Image
                      src="/Group (2).svg"
                      alt="teg-line"
                      title="teg-line"
                      width={18}
                      height={18}
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AboutMaster);
