import React from 'react';

import '../../home/Benner/styles.scss';
import Image from 'next/image';
import '../ArtfillNews/style.scss';

const ArtfillNews: React.FC = () => {
  return (
    <>
      <section className="py-8 artfillnews-content">
        <div className="section-container">
          <div className="artfill-inner-content pb-2">
            <h2>Artfills in news </h2>
            <p>
              <Image
                src="/Group (1).svg"
                alt="teg-line"
                title="teg-line"
                width={143}
                height={14}
                loading="lazy"
                className="d-none-md-down"
              />
              <Image
                src="/course-tab-divider.png"
                alt="Card Divider"
                title="Card Divider"
                width={141}
                height={10}
                className="d-none-md-up"
              />
            </p>
          </div>
          <div className="artfills-brand-content">
            <div className="row">
              <p>
                <a
                  href="https://businessnewsthisweek.com/business-profile/kathak-dancer-turned-entrepreneur-in-a-mission-to-make-indian-art-forms-accessible-to-children-and-adults-through-her-online-learning-platform/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/L-1.svg"
                    alt="brand-name"
                    title="brand-name"
                    width={162}
                    height={41}
                    loading="lazy"
                  />
                </a>
              </p>
              <p>
                <a
                  href="https://www.thehansindia.com/business/what-is-the-best-way-to-learn-arts-online-777706"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/air.svg"
                    alt="brand-name"
                    title="brand-name"
                    width={162}
                    height={41}
                    loading="lazy"
                  />
                </a>
              </p>
              <p>
                <a
                  href="https://yourstory.com/companies/artfills"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/logo_yourstory_new.svg"
                    alt="brand-name"
                    title="brand-name"
                    width={162}
                    height={41}
                    loading="lazy"
                  />
                </a>
              </p>
              <p>
                <a
                  href="https://smefutures.com/looking-back-on-2021-how-it-fared-for-women-start-up-owners-and-their-plans-for-2022/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/smecolorlogo.svg"
                    alt="brand-name"
                    title="brand-name"
                    width={162}
                    height={41}
                    loading="lazy"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(ArtfillNews);
