import React from 'react';
import '../WhyArtfills/style.scss';

const WhyArtfills: React.FC = () => {
  return (
    <>
      <section className="bg-white whyartfills-content">
        <div className="section-container">
          <div className="whyfills-inner-content">
            <h2 className="pb-6">Why Artfills ?</h2>
            <h3>
              Artfills Ensures easy to Follow, Fun & Authentic Training of
              Indian Arts for Children & Adults
            </h3>
            <p className="pt-5">
              Indian Arts is a time-tested way to nurture your creativity, have
              a healthy mind and thus enable a joyful life. We are an Artist
              lead company making sure the best ways of learning are available
              to everyone in your family.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(WhyArtfills);
