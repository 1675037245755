'use client';
import type { NextPage } from 'next';

import Benner from '@/components/home/Benner';
import WhyArtfills from '@/components/home/WhyArtfills';
import OurCourse from '@/components/home/OurCourse';
import TimeTest from '@/components/home/TimeTest';
import Rating from '@/components/common/Rating';
import Testimonial from '@/components/home/Testimonials';
import ArtfillNews from '@/components/home/ArtfillNews';
import AboutMaster from '@/components/home/AboutMaster';
import InviteMembers from '@/components/common/InviteMembers';
import WhatsAppSection from '@/components/common/WhatsAppSection';
import './style.scss';

const HomePage: NextPage = () => {
  return (
    <div className="home-page-content main-h">
      <Benner />
      <WhyArtfills />
      <OurCourse />
      <TimeTest />
      <Rating />
      <Testimonial />
      <ArtfillNews />
      <AboutMaster />
      <WhatsAppSection />
      <InviteMembers />
    </div>
  );
};

export default HomePage;
