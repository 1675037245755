import React from 'react';
import '../OurCourse/style.scss';
import Image from 'next/image';
import Link from 'next/link';

const OurCourse: React.FC = () => {
  return (
    <>
      <section className="ourcourse-content section-spacing">
        <div className="section-container">
          <h2 className="main-section-head text-center text-white">
            Our Courses
          </h2>
          <p>
            <Image
              src="/dv.svg"
              alt="divider"
              title="divider"
              width={255}
              height={12}
              priority
              rel="preload"
            />
          </p>
          <div className="ourcourse-inner-content">
            <div className="flex flex-row w-full justify-center flex-wrap gap-12">
              <div className="basis-[100%] lg:basis-[451px] xl:basis-[451px] 2xl:basis-[551px]">
                <div className="top-heading">
                  <span></span>
                  <span>For Adults</span>
                </div>
                <Link
                  href="/adults-live-course"
                  className="right-arrow"
                  title="adults-live-course"
                >
                  <div className="right-side-content">
                    <div className="courses-kids-content i-courses">
                      <div className="card-content">
                        <div className="img-wrap">
                          <Image
                            src="/kathak-adults-landing-page.png"
                            alt="Kathak Aalamban - Adults"
                            title="divider"
                            width={350}
                            height={233}
                            priority
                            rel="preload"
                          />
                        </div>
                        <div>
                          <h3>Kathak Aalamban - Adults</h3>
                          <span>Foundation Course for Beginners</span>
                        </div>
                      </div>
                      <div className="bottom-line">
                        <div className="line-left-content">
                          <h3>Kathak for Adults</h3>
                          <span className="circle">
                            <Image
                              src="/Ellipse 117.svg"
                              alt="elisp"
                              title="elisp"
                              width={6}
                              height={6}
                              priority
                              rel="preload"
                            />
                          </span>
                          <span className="age-v">Age 18-60+</span>
                        </div>
                        <div className="line-right-content">
                          <Image
                            src="/arrow-v.png"
                            alt="image"
                            title="image"
                            width={40}
                            height={16}
                            priority
                            rel="preload"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="basis-[100%] lg:basis-[451px] xl:basis-[451px] 2xl:basis-[551px]">
                <div className="top-heading second-top-heading">
                  <span></span>
                  <span>For Adults</span>
                </div>
                <Link
                  href="/vocals-live-course"
                  className="right-arrow"
                  title="vocals-live-course"
                >
                  <div className="right-side-content">
                    <div className="courses-kids-content">
                      <div className="card-content">
                        <div className="img-wrap">
                          <Image
                            src="/kathak-vocals-banner01.png"
                            alt="Vocals Aalamban For Adults"
                            title="divider"
                            width={350}
                            height={233}
                            priority
                            rel="preload"
                          />
                        </div>
                        <div>
                          <h3>Vocals Aalamban - Adults</h3>
                          <span>Foundation Course for Beginners</span>
                        </div>
                      </div>
                      <div className="bottom-line">
                        <div className="line-left-content">
                          <h3>Vocals for Adults</h3>
                          <span className="circle">
                            <Image
                              src="/Ellipse 117.svg"
                              alt="elisp"
                              title="elisp"
                              width={6}
                              height={6}
                              priority
                              rel="preload"
                            />
                          </span>

                          <span className="age-v">Age 18-60+</span>
                        </div>

                        <div className="line-right-content">
                          <Image
                            src="/arrow-v.png"
                            alt="image"
                            title="image"
                            width={40}
                            height={16}
                            priority
                            rel="preload"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div className="flex flex-row w-full justify-center flex-wrap">
                <div className="basis-[100%] lg:basis-[451px] xl:basis-[451px] 2xl:basis-[551px]">
                  <div className="top-heading !mt-0">
                    <span></span>
                    <span>For Kids</span>
                  </div>

                  <div className="left-side-content">
                    <Link
                      href="/kids-classes-age-6-12"
                      className="right-arrow"
                      title="kids-classes-age-6-12"
                    >
                      <div className="courses-kids-content">
                        <div className="card-content">
                          <div className="img-wrap">
                            <Image
                              src="/Kathak-Aadita-Kids.png"
                              alt="Kathak Aadita - Kids"
                              title="divider"
                              width={350}
                              height={233}
                              priority
                              rel="preload"
                            />
                          </div>
                          <div>
                            <h3>Kathak Aadita - Kids</h3>
                            <span>Foundation Course for Beginners</span>
                          </div>
                        </div>
                        <div className="bottom-line">
                          <div className="line-left-content">
                            <h3>Kathak for Kids</h3>
                            <span className="circle">
                              <Image
                                src="/Ellipse 117.svg"
                                alt="elisp"
                                title="elisp"
                                width={6}
                                height={6}
                                priority
                                rel="preload"
                              />
                            </span>
                            <span className="age-v">Age 6-12</span>
                          </div>
                          <div className="line-right-content">
                            <Image
                              src="/arrow-v.png"
                              alt="image"
                              title="image"
                              width={40}
                              height={16}
                              priority
                              rel="preload"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              {/* <div className="left-side-content">
                <div className="courses-kids-content">
                  <div className="card-content">
                    <div className="img-wrap">
                      <Image
                        src="/Vocals-Aarambh–Kids.png"
                        alt="divider"
                        title="divider"
                        width={350}
                        height={233}
                        priority
                        rel="preload"
                      />
                    </div>
                    <div>
                      <h3>Vocals Aarambh - Kids</h3>
                      <span>Foundation Course for Beginners</span>
                    </div>
                  </div>
                  <div className="bottom-line">
                    <div className="line-left-content">
                      <h3>Vocals for Kids</h3>
                      <span className="circle">
                        <Image
                          src="/Ellipse 117.svg"
                          alt="elisp"
                          title="elisp"
                          width={6}
                          height={6}
                          priority
                          rel="preload"
                        />
                      </span>
                      <span className="age-v">Age 6-12</span>
                    </div>
                    <div className="line-right-content">
                      <Link
                        href="/vocals"
                        className="right-arrow"
                        title="vocals"
                      >
                        <Image
                          src="/arrow-v.png"
                          alt="image"
                          title="image"
                          width={40}
                          height={16}
                          priority
                          rel="preload"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row"></div>
          </div>
          <div></div>
        </div>
      </section>
    </>
  );
};

export default React.memo(OurCourse);
